<template>
  <div class="account-info-wrapper">
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel :left-cols="6" :right-cols="5" disable-title-icon :top-margin="false">
        <template v-slot:title>General Information </template>
        <template v-slot:actions>
          <v-row no-gutters class="pl-2">
            <v-col cols="8" class="pa-0 ma-0 d-flex justify-end hb-font-header-3-medium">
            </v-col>
          </v-row>
        </template>
        <template v-slot:content>
          <div class="text-field-wrapper-parent">
            <hb-form label="Company Name" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active"
              editable :empty="!formdata.name" required :disable-save="disableSave">
              <template v-slot:display>
                {{ formdata.name }}
              </template>
              <template v-slot:edit>
                <HbTextField class="mt-1" v-model="formdata.name" v-validate="{
      required: true,
      regex: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
    }" id="company-name" data-vv-scope="default" data-vv-name="company-name" data-vv-as="Company Name"
                  :error="errors.has('default.company-name')" placeholder="Company Name" />
              </template>
            </hb-form>
            <hb-form label="Trading Name" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active"
              editable :empty="!formdata.trading_name" :disable-save="disableSave">
              <template v-slot:display>
                {{ formdata.trading_name }}
              </template>
              <template v-slot:edit>
                <HbTextField v-model="formdata.trading_name"
                  v-validate="{ regex: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/ }" data-vv-scope="default"
                  data-vv-name="trading_name" data-vv-as="Trading Name" :error="errors.has('default.trading_name')"
                  placeholder="Trading Name" />
              </template>
            </hb-form>
            <hb-form label="Country" required :disable-save="disableSave">
              <template>
                {{ formdata.address.country }}
              </template>
            </hb-form>
            <hb-form label="Business Number" required @cancel="handleEmittedCancel" @save="handleEmittedSave"
              :active.sync="active" editable :empty="!formdata.taxId" :disable-save="disableSave">
              <template v-slot:display>
                {{ formdata.taxId }}
              </template>
              <template v-slot:edit>
                <HbTextField v-model="formdata.taxId" v-validate="taxIdValidationRule" placeholder="Business Number"
                  data-vv-scope="default" data-vv-name="bNumber" id="bNumber" data-vv-as="Business Number"
                  :hide-details="!errors.collect('default.bNumber').length" :error="errors.has('default.bNumber')"
                  data-vv-validate-on="'blur'" :disabled="!formdata.address.country" />
              </template>
            </hb-form>
            <hb-form label="Business Address" @cancel="handleEmittedCancel" @save="handleEmittedSave"
              :active.sync="active" editable :empty="!formdata.address" full multiple :disable-save="disableSave">
              <template v-slot:display>
                <v-row no-gutters>
                  <v-col v-if="formdata.address.address">
                    {{ formdata.address.address }} {{ formdata.address.city }}
                    <span v-if="formdata.address.state"> , </span>
                    {{ formdata.address.state }} {{ formdata.address.zip }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:edit>
                <HbTextField v-model="formdata.address.address" data-vv-scope="address" data-vv-name="street-address"
                  data-vv-as="Street Addresssss" :error="errors.has('address.street-address')"
                  placeholder="Street Address" @input="handleEmittedSave" />
                <v-row no gutters>
                  <v-col cols="3">
                    <HbAutocomplete v-model="formdata.address.city" :items="cityListMixin[0]" single-line
                      v-validate="'max:45'" data-vv-scope="address" data-vv-name="city" data-vv-as="city"
                      :error="errors.has('address.city')" placeholder="City" @input="handleEmittedSave" />
                  </v-col>
                  <v-col cols="3">
                    <HbAutocomplete v-model.lazy="formdata.address.state" :items="stateListMixin[0] || []" single-line
                      v-validate="stateListMixin[i] && stateListMixin[i].length
      ? 'required'
      : ''
      " data-vv-scope="address" data-vv-name="State" data-vv-as="state" item-text="name"
                      item-value="value" :error="errors.has('address.state')" placeholder="State"
                      @input="handleEmittedSave" />
                  </v-col>
                  <v-col cols="2">
                    <HbTextField v-model="formdata.address.zip" data-vv-scope="address" single-line
                      data-vv-name="street-address" data-vv-as="Zip" :error="errors.has('address.zip')" label="ZIP"
                      @input="handleEmittedSave" />
                  </v-col>
                </v-row>
              </template>
            </hb-form>
            <hb-form label="Primary Contact" required full @cancel="handleEmittedCancel" @save="handleEmittedSave"
              :active.sync="active" editable :empty="!formdata.contact" multiple :disable-save="disableSave">
              <template v-slot:display>
                <v-row no-gutters>
                  <v-col cols="12">
                    {{ formdata.contact.first }} {{ formdata.contact.last }}
                  </v-col>
                  <v-col cols="12">
                    {{ formdata.contact.email }}
                  </v-col>
                  <v-col cols="12">
                    {{ formdata.contact.Phones[0].type }} :
                    {{ formdata.contact.Phones[0].phone | formatPhone }}
                  </v-col>
                </v-row>
                <v-row no-gutters> </v-row>
              </template>
              <template v-slot:edit>
                <v-row no-gutters>
                  <v-col cols="4">
                    <HbTextField v-model="formdata.contact.first" v-validate="{ required: true }"
                      data-vv-scope="contact" data-vv-name="contact-first-name" data-vv-as="FirstName" label="FirstName"
                      :error="errors.has('contact.contact-first-name')" />
                  </v-col>
                  <v-col cols="4">
                    <HbTextField v-model="formdata.contact.last" v-validate="'required|max:45'" data-vv-scope="contact"
                      data-vv-name="contact-second-name" data-vv-as="LastName" label="SecondName"
                      :error="errors.has('contact.contact-second-name')" />
                  </v-col>
                </v-row>
                <div class="d-flex" no gutters>
                 
                    <HbTextField v-model="formdata.contact.email" v-validate="'required|max:45|email'"
                      data-vv-scope="contact" data-vv-name="contact-email" data-vv-as="Email"
                      :error="errors.has('contact.contact-email')" label="Contact Email" />
                    <hb-checkbox :id="'contactp_send_invoice_email_' + i" :name="'contact_send_invoice_email_' + i"
                    :data-name="'contact_send_invoice_email_' + i" v-model="formdata.contact.send_invoices_email" hide-details>
                      <template v-slot:label>
                        <span class="text-capitalize">Email Invoice</span>
                      </template>
                    </hb-checkbox>
                </div>
                <HbSelect v-model="formdata.contact.Phones[0].type" class="mt-5" v-validate="'required'"
                  :items="phone_types" data-vv-scope="contact" data-vv-name="phone" data-vv-as="Phone"
                  :error="errors.has('contact.phone')" placeholder="Select Phone" />
                <v-row no gutters>
                  <v-col cols="2" sm="2" xl="1">
                    <hb-autocomplete v-model="formdata.contact.Phones[0].code" box :items="countryCodesList"
                      label="Code" data-vv-scope="contact" data-vv-name="Code" data-vv-as="phone code"
                      :error="errors.has('contact.Code') > 0" :clearable="false" v-validate="'required'"
                      @change="checkValidPhone(formdata.contact.Phones[0].code, formdata.contact.Phones[0].phone)"
                      @focusout="checkValidPhone(formdata.contact.Phones[0].code, formdata.contact.Phones[0].phone)">
                      <template v-slot:selection="data">
                        +{{ data.item }}
                      </template>

                      <template v-slot:item="data"> +{{ data.item }} </template>
                    </hb-autocomplete>
                  </v-col>
                  <v-col cols="4" lg="3" class="mt-3 pa-0" :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown, }">
                    <HbTextField v-model="formdata.contact.Phones[0].number" box v-validate="'required|max:45'"
                      placeholder="Phone Number" v-mask="phoneMask(formdata.contact.Phones[0].code)"
                      data-vv-scope="contact" data-vv-name="number" data-vv-as="Phone Number"
                      :error="errors.collect('contact.number').length > 0 || !checkValidPhone(formdata.contact.Phones[0].code, formdata.contact.Phones[0].number.toString().replace(/\D/g, '')) ? true : false" />
                  </v-col>
                  <v-col class="mt-2 ml-2" :class="{ 'pl-2': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown, }">
                    <HbCheckbox v-model="formdata.contact.Phones[0].sms" label="Receive Texts?" />
                  </v-col>
                </v-row>
              </template>
            </hb-form>
            <hb-form label="Sqm Discount" description="Choose a discount to apply to this National Account."
              @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable full
              :empty="!formdata.promotion_id" :disable-save="disableSave">
              <template v-slot:display>
                <p>{{ selectedDiscountLabel.label }}</p>
              </template>
              <template v-slot:edit>
                <HbSelect v-model="formdata.promotion_id" :items="discounts" item-text="label" item-value="key"
                  data-vv-scope="discount" data-vv-name="discount" data-vv-as="discount"
                  :error="errors.has('default.discount')" :clearable="true" @click:clear="formdata.promotion_id = ''" />
              </template>
            </hb-form>
            <hb-form label="Departments" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active"
              editable :empty="!formdata.departments" full :disable-save="disableSave">
              <template v-slot:display>
                <hb-chip v-for="(item, i) in formdata.departments" :key="'editable_chip_' + i"
                  @click="handleChipCloseClick(item.name)" pointer>
                  {{ item.name }}
                </hb-chip>
                <p v-if="!formdata.departments?.length">No departments selected.</p>
              </template>
              <template v-slot:edit>
                <v-row class="mb-0">
                  <v-col cols="4">
                    <HbTextField v-model="departmentName" box data-vv-scope="department" data-vv-name="department-name"
                      data-vv-as="department Name" v-validate="'required|max:60'"
                      :error="errors.has('department.department-name')" placeholder="Enter Department Name" />
                  </v-col>
                  <v-col cols="6">
                    <hb-btn color="secondary" medium prepend-icon="mdi-plus" @click="addDepartment()">Add</hb-btn>
                  </v-col>
                </v-row>
                <HbTextField full readonly>
                  <template v-slot:prepend-inner>
                    <div class="chip-block custom-chip-block">
                      <hb-chip v-for="(item, i) in formdata.departments" :key="'editable_chip_' + i"
                        @click="handleChipCloseClick(item.name)" pointer :editable="editable(item)">
                        {{ item.name }}
                      </hb-chip>
                    </div>
                  </template>
                </HbTextField>
              </template>
            </hb-form>
            <hb-form label="PO Number" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active"
              editable :empty="!formdata.po_number" :disable-save="disableSave">
              <template v-slot:display>
                {{ formdata.po_number }}
              </template>
              <template v-slot:edit>
                <HbTextField class="mt-1" v-model="formdata.po_number" v-validate="{ regex: /^[\x20-\x7E]{1,45}$/ }"
                  data-vv-scope="default" data-vv-name="po_number" data-vv-as="PO Number"
                  :error="errors.has('default.po_number')" placeholder="PO Number" />
              </template>
            </hb-form>
            <hb-form label="Billing Cycle" required @cancel="handleEmittedCancel" @save="handleEmittedSave"
              :active.sync="active" editable :empty="!formdata.billing_cycle" :disable-save="disableSave">

              <template v-slot:display>
                {{ getBillingCycleLabel(formdata.billing_cycle) }}

              </template>
              <template v-slot:edit>
                <HbSelect v-model="formdata.billing_cycle" :items="billingCycle" v-validate="{ required: true }"
                  item-text="label" item-value="key" placeholder="Select billingCycle" data-vv-scope="default"
                  data-vv-name="billingCycle" data-vv-as="billingCycle" :error="errors.has('default.billingCycle')" />
              </template>
            </hb-form>
            <hb-form label="Next Review Date" required @cancel="handleEmittedCancel" @save="handleEmittedSave"
              :active.sync="active" editable :empty="!formdata.next_review_date" :disable-save="disableSave">
              <template v-slot:display>
                {{ formdata.next_review_date | formatdate }}
              </template>
              <template v-slot:edit>
                <HbDatePicker v-model="formdata.next_review_date" @click:clear="formdata.next_review_date = null"
                  v-validate="'required'" label="MM/DD/YYYY" :clearable="true" :min="new Date().toISOString()"
                  data-vv-scope="default" clear-icon="mdi-close-circle" :solo="false" :single-line="true"
                  data-vv-name="next_review_date" data-vv-as="Review date"
                  :error="errors.collect('default.next_review_date').length > 0" />
              </template>
            </hb-form>
          </div>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
<!-- Additional contact starts -->
<v-expansion-panels v-model="addPanel" multiple class="mb-4">
      <hbExpansionPanel :left-cols="6" :right-cols="5" disable-title-icon :top-margin="true" :expand-icon-off="form.Relationships.length === 0">
        <template v-slot:title>Additional Contacts</template>
        <template v-slot:actions>
          <div v-if="!form.Relationships.length">
            <hb-link class="hb-default-font-size" @click="addRelationship(0)">Click to Add</hb-link>
          </div>
        </template>
        <template v-slot:content>
          <v-expansion-panel class="ma-0 pa-0" v-for="(r, i) in form.Relationships" :key="i">
            <v-expansion-panel-header class="hb-table-label hb-subpanel-header" >
              <template v-slot:default="{ open }">
                <v-fade-transition leave-absolute>
                  <v-col cols="8" class="pa-0 ma-0">
                    <span class="hb-default-font-size font-weight-medium" v-if="r.Contact.first || r.Contact.last"> {{ r.Contact.first }} {{ r.Contact.last }}<span v-if="r.Contact.title"> ({{ r.Contact.title }})</span></span>
                <span class="hb-default-font-size font-weight-medium" v-else>New Contact</span>
              </v-col>
                </v-fade-transition>
            </template>
              <template v-slot:actions>
                <div v-if="!!r.Contact.id">
                  <hb-link @click="removeContact(i)" v-if="!newContact" class="hb-default-font-size  mr-3">Remove </hb-link>
                </div>
                <v-icon>$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content  class="pa-0 ma-0" elevation="0">
              <hb-form label="Name" required @cancel="handleEmittedCancel" @save="handleAdditionalContactSave('Name',i)"
                :active.sync="active" editable :start-open="r.Contact.id ? false : true"
                :empty="!r.Contact.first && !r.Contact.last" :hide-actions="r.Contact.id ? false : true"
                :disable-save="disableSave">
                <template v-slot:display>
                  {{ r.Contact.first }} {{ r.Contact.last }}
                </template>
                <template v-slot:edit class="ma-0 pa-0" >
                  <v-row no-gutters>
                    <v-col  cols="12" sm="6" class="ma-0">
                      <HbTextField :id="'relationship_first_' + i" :name="'relationship_first_' + i"
                        v-model="r.Contact.first" v-validate="'required|max:45'" data-vv-as="first name"
                        label="First Name" single-line data-vv-scope="contacts"
                        :error="errors.collect('contacts.relationship_first_' + i).length > 0" data-vv-name="firstName">
                      </HbTextField>
                    </v-col>
                    <v-col  cols="12" sm="6" class="pl-3">
                      <HbTextField :id="'relationship_last_' + i" :name="'relationship_last_' + i"
                        v-model="r.Contact.last" v-validate="'required|max:45'" data-vv-as="last name" label="Last Name"
                        single-line data-vv-scope="contacts"
                        :error="errors.collect('contacts.relationship_last_' + i).length > 0"></HbTextField>
                    </v-col>
                  </v-row>
                </template>
              </hb-form>
              <hb-form label="Title" required @cancel="handleEmittedCancel" @save="handleAdditionalContactSave('Title',i)"
                :active.sync="active" editable :start-open="r.Contact.id ? false : true"
                :hide-actions="r.Contact.id ? false : true" :empty="!r.Contact.title" :disable-save="disableSave">
                <template v-slot:display>
                  {{ r.Contact.title }}
                </template>
                <template v-slot:edit>
                  <HbTextField :id="'relationship_title_' + i" :name="'relationship_title_' + i"
                    v-model="r.Contact.title" v-validate="'required|max:45'" data-vv-as="title" data-vv-name="title" label="Title"
                    single-line data-vv-scope="contacts"
                    :error="errors.collect('contacts.relationship_title_' + i).length > 0"></HbTextField>
                </template>
              </hb-form>
              <hb-form label="Email" required @cancel="handleEmittedCancel" @save="handleAdditionalContactSave('Email',i)"
                :active.sync="active" editable :start-open="r.Contact.id ? false : true" :empty="!r.Contact.email"
                :hide-actions="r.Contact.id ? false : true" :disable-save="disableSave">
                <template v-slot:display>
                  {{ r.Contact.email }}
                </template>
                <template v-slot:edit>
                 <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0 mt-3 pr-3">
                    <HbTextField :id="'relationship_email_' + i" :name="'relationship_email_' + i"
                    v-model="r.Contact.email"
                    data-vv-name="relationship_email"
                    v-validate="{ required: true,max: 100, email: true, regex: /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/ }"
                    data-vv-as="alternate contact email" data-vv-scope="contacts" label="Email" single-line
                    :error="errors.collect('contacts.relationship_email_'+i).length > 0"></HbTextField>
                  </v-col>
                    <v-col cols="6" class="ma-0 pa-0 mt-3">
                      <hb-checkbox :id="'relationship_send_invoice_email_' + i" :name="'relationship_send_invoice_email_' + i"
                        :data-name="'relationship_send_invoice_email_' + i" v-model="r.Contact.send_invoices_email" hide-details>
                        <template v-slot:label>
                          <span class="text-capitalize">Email Invoice </span>
                        </template>
                      </hb-checkbox>
                    </v-col>
                 </v-row>
                </template>
              </hb-form>
              <hb-form label="Phone" required @cancel="handleEmittedCancel" @save="handleAdditionalContactSave('Phone',i)"
                :active.sync="active" editable :start-open="r.Contact.id ? false : true"
                :empty="!r.Contact.Phones[0].number" :hide-actions="r.Contact.id ? false : true"
                :disable-save="disableSave">
                <template v-slot:display>
                  {{ r.Contact.Phones[0].type }} &nbsp;&nbsp;+{{ r.Contact.Phones[0].code }} {{ r.Contact.Phones[0].number }}&nbsp;&nbsp;<span
                    class="hb-text-light"> {{ r.Contact.Phones[0].sms ? "Can SMS" : "Can not SMS" }} </span>
                </template>
                <template v-slot:edit>
                  <v-row class="ma-0 pa-0" v-for="(secondaryphone, index) in r.Contact.Phones" :key="index">
                    <v-col cols="12" class="hb-z-index-99999 ma-0 pa-0 hb-forms-max-width-498">
                      <HbSelect :items="['Phone', 'Cell', 'Home', 'Work', 'Fax', 'Other']"
                        v-validate="'required|max:45'" v-model="secondaryphone.type" label="Type" single-line
                        :clearable="false" :id="'relationship_phone_type_' + index"
                        :name="'relationship_phone_type_' + index" data-vv-as="alternate contact phone type"
                        data-vv-scope="contacts"
                        :error="errors.collect('contacts.relationship_phone_type_' + index).length > 0"></HbSelect>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0 mt-3 pr-3">
                      <HbAutocomplete :items="countryCodesList"
                        :hide-details="!errors.collect('contacts.phone_code_' + i).length" v-validate="'required'"
                        :clearable="false" v-model="secondaryphone.code" label="Code" single-line
                        :id="'relationship_phone_code_' + i" data-vv-scope="contacts" data-vv-as="phone code"
                        :name="'relationship_phone_code_' + i"
                        :error-messages="errors.collect('contacts.phone_code_' + i)"
                        @change="checkValidPhone(secondaryphone.code, secondaryphone.number)"
                        @focusout="checkValidPhone(secondaryphone.code, secondaryphone.number)">
                        <template v-slot:selection="data">
                          +{{ data.item }}
                        </template>
                        <template v-slot:item="data">
                          +{{ data.item }}
                        </template>
                      </HbAutocomplete>
                    </v-col>
                    <v-col cols="5" class="ma-0 pa-0 mt-3 pr-3">
                      <HbTextField v-validate="'required|max:45'" v-model="secondaryphone.number" label="Number"
                        single-line :id="'relationship_phone_' + i" :name="'relationship_phone_' + i"
                        data-vv-scope="contacts" data-vv-as="alternate contact phone"
                        :error="errors.collect('contacts.relationship_phone_' + i).length > 0 ||  !checkValidPhone(secondaryphone.code, secondaryphone.number.toString().replace(/\D/g, '')) ? true : false"
                        v-mask="phoneMask(secondaryphone.code)">
                      </HbTextField>
                    </v-col>
                    <v-col cols="3" class="ma-0 pa-0 mt-3">
                      <hb-checkbox :id="'relationship_sms_' + i" :name="'relationship_sms_' + i"
                        :data-name="'relationship_sms_' + i" v-model="secondaryphone.sms" hide-details>
                        <template v-slot:label>
                          <span class="text-capitalize">SMS</span>
                        </template>
                      </hb-checkbox>
                    </v-col>
                  </v-row>
                </template>
              </hb-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
        <template v-slot:footer>
          <hb-bottom-action-bar cancel-off :top-border="false" v-if="form.Relationships.length">
            <template v-slot:right-actions>
              <div v-if="newContact">
                <hb-link @click="removeRelationships()">Cancel</hb-link>
                <hb-btn @click="saveContact()" :loading="loading" :disabled="!form.Relationships[form.Relationships.length-1].Contact.first && !form.Relationships[form.Relationships.length-1].Contact.last && !form.Relationships[form.Relationships.length-1].Contact.title && !form.Relationships[form.Relationships.length-1].Contact.email && !form.Relationships[form.Relationships.length-1].Contact.Phones[0].number">Save</hb-btn>
              </div>
              <hb-btn v-else color="secondary" prepend-icon="mdi-plus" @click="addRelationship()">Add New
                Contact</hb-btn>
            </template>
          </hb-bottom-action-bar>
        </template>
      </hbExpansionPanel>
    </v-expansion-panels>
<!-- Additional contact ends -->

    <!--   Restricted Permission warning popup -->
    <hb-modal v-model="showNoPermissionDialog" size="medium" title="Unable To Edit National Accounts" :footerOff="true"
      confirmation show-help-link @close="showNoPermissionDialog = false">
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">The signed in account does not have permission to edit national accounts.</div>
          <div>To edit national accounts, contact your administrator to enable permission.</div>
        </div>
      </template>
    </hb-modal>
    <hb-modal v-model="confirmRemoveContact" show-help-link v-if="confirmRemoveContact" size="medium"
      title="Delete Additional Contact" confirmation>
      <template v-slot:content>
        <div class="py-4 px-6">
          Are you sure you want to delete the selected additional contact?
          <br /><br />
          This action cannot be undone.
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="destructive" @click="deleteRelationship(selectedContact)">Delete</hb-btn>
      </template>
    </hb-modal>
    <!--  Restricted Permission warning popup // -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { countryStateCityMixin } from "../../../mixins/countryStateCityMixin";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import HbDatePicker from "../../assets/HummingbirdDatepicker.vue";
import { parsePhoneNumber } from "libphonenumber-js";
import moment from 'moment';
import Contact from '../Contact.vue';
import { EventBus } from "../../../EventBus.js";
import api from "../../../assets/api.js";
import PHONE from "@/constants/phone.js";
import { validateEmailPhoneMixin } from "../../../mixins/validateEmailPhoneMixin.js";
export default {
  name: "AccountInformation",
  mixins: [countryStateCityMixin, notificationMixin,validateEmailPhoneMixin],
  props: ["contact", "propertyView"],
  components: {
    HbDatePicker,
    Contact,
  },
  data() {
    return {
      defaultCountry: 'United States',
      contactsSubpanel: 1,
      hideAction: true,
      newContact: false,
      form: {
        salutation: '',
        first: '',
        middle: '',
        last: '',
        suffix: '',
        email: '',
        company: '',
        notes: '',
        dob: null,
        ssn: '',
        gender: '',
        driver_license: '',
        driver_license_exp: '',
        driver_license_country: '',
        driver_license_state: '',
        driver_license_city: '',
        active_military: false,
        Vehicles: [],
        Employment: [],
        Phones: [],
        Addresses: [],
        Relationships: [],
        Representative: {},
        Military: {
          id: {}
        },
        Business: {
          Address: {},
        },
        taxpayer_number: ''
      },
      loading: false,
      disableSave: true,
      showNoPermissionDialog: false,
      panel: [0],
      addPanel:[0],
      departmentName: "",
      description: "",
      snackbar: false,
      list: false,
      type: "",
      active: "",
      //formdata for edit details start
      discounts: [],
      temp_promotion_id: '',
      originalFormdata: {},
      formdata: {
        name: "",
        trading_name: "",
        taxId: "",
        address: {
          id: "",
          country: "",
          address: "",
          city: "",
          state: "",
          zip: "",
        },
        contact: {
          id: "",
          first: "",
          last: "",
          send_invoices_email: "",
          email: "",
          Phones: [
            {
              id: "",
              phone: "",
              type: "",
              sms: false,
              number: "",
            },
          ],
        },
        promotion_id: "",
        departments: [],
        po_number: "",
        billing_cycle: 'monthly',
        next_review_date: "",
      },
      //formdata for edit details end
      addedDepartments: [],
      deletedDepartments: [],
      phone_types: PHONE.TYPES,
      countryCodesList: PHONE.COUNTRY_CODES,
      countryList: [
        { key: "Australia", label: "Australia" },
        { key: "New Zealand", label: "New Zealand" },
      ],
      billingCycle: [
        { key: "monthly", label: 'Monthly' },
        { key: "quarterly", label: 'Quarterly' },
        { key: "semi-annual", label: 'Semi Annual' },
        { key: "annual", label: 'Annual' },
      ],
      confirmRemoveContact: false,
      selectedContact: '',
      originalRelationshipData: '',
    };
  },
  watch: {
    formdata: {
      handler(newVal) {
        console.log(newVal)
        console.log(this.originalFormdata);
        this.disableSave = JSON.stringify(newVal) === JSON.stringify(this.originalFormdata);
      },
      deep: true,
      immediate: true, // This ensures the watcher is initialized immediately
    },
    contact: {
      handler(newVal) {
        this.setAccountInfo()
        this.updateComponent();
        this.loading = false
      },
      deep: true
    },
    'form.Relationships': {
      handler(newVal, oldVal) {
        this.disableSave = JSON.stringify(newVal) == this.originalRelationshipData;
      },
      deep: true
    },
  },
  created() {
    this.setCountriesListEvent();
    this.form.Relationships = JSON.parse(JSON.stringify(this.contact.Relationships))
    .filter(relationship => relationship.is_national_account_alt === 1); // only take is_national_account_alt means acc level additional contacts
    this.buildFormRelationShip()

  },
  mounted() {
    this.setCountry();
    this.setAccountInfo();
    this.getDiscount()
  },
  computed: {
    ...mapGetters({
      hasPermission: "authenticationStore/rolePermission",
    }),
    taxIdValidationRule() {
      if (this.formdata.address.country === "Australia") {
        return "required|length:13|numeric";
      } else if (this.formdata.address.country === "New Zealand") {
        return "required|length:11|numeric";
      } else {
        return "required|numeric"; // Default rule
      }
    },
    selectedDiscountLabel() {
      const selectedPromotion = this.discounts.find(discount => discount.key === this.formdata.promotion_id);
      return selectedPromotion;
    }
  },
  filters: {
    formatdate(value) {
      if (value) {
        return moment(value).format('DD/MM/YYYY');
      }
    },
  },
  methods: {
    removeRelationships(i) {
      this.form.Relationships.splice(this.form.Relationships.length - 1, 1);
      this.newContact = false
    },
    addRelationship(i) {
      this.form.Relationships.push({
        id: null,
        related_contact_id: null,
        lease_data: [],
        selected_leases_list: [],
        selected_designations_list: [],
        type: '',
        Contact: {
          id: null,
          first: '',
          last: '',
          email: '',
          send_invoices_email:'',
          Phones: [
            {
              id: null,
              code: '1',
              phone: '',
              number:'',
              type: 'Cell',
              sms: false
            }
          ],
          Addresses: [{
            type: '',
            Address: {
              address: '',
              address2: '',
              country: this.defaultCountry,
              city: '',
              state: '',
              zip: '',
            },
          }]
        },
      });
      this.newContact = true
      this.hideAction = true   // hide single save button
      if (this.addPanel.length > 0) {
        // Clear the opened panels before pushing the new relationship
        this.addPanel = [0]; //made parent open
      }
      this.addPanel.push(this.form.Relationships.length) //open newly added 
    },
    getBillingCycleLabel(key) {
      const cycle = this.billingCycle.find(cycle => cycle.key === key);
      return cycle ? cycle.label : key;
    },
    editable(item) {
      return item.lease_id ? false : true
    },
    async getDiscount() {
      await api.get(this, api.PROMOTION).then(async (res) => {
        this.discounts = res.promotions.map(promotion => ({
          key: promotion.id,
          label: `${promotion.name} - ${promotion.value}%`
        }));
      })
    },
    async setAccountInfo() {
      let data;
      try {
        await api
          .get(
            this,
            api.NATIONAL_ACCOUNT +
            `${this.$route.query.na_id}?contact_id=${this.$route.params.contact_id}`
          )
          .then(async (res) => {
            data = res[0];
          });
        let contactData = JSON.parse(JSON.stringify(this.contact))
        this.formdata.name = data.name;
        this.formdata.taxId = data.tax;
        this.formdata.trading_name = data.trading_name;
        this.formdata.po_number = data.po_number;
        this.formdata.billing_cycle = data.billing_cycle
        this.formdata.promotion_id = data.promotion_id;
        this.temp_promotion_id = data.promotion_id;
        this.formdata.address.id = contactData.Business.Address.id;
        this.formdata.address.country = contactData.Business.Address.country;
        this.formdata.address.address = contactData.Business.Address.address;

        this.formdata.contact.id = contactData.id;
        this.formdata.contact.first = contactData.first;
        this.formdata.contact.last = contactData.last;
        this.formdata.contact.email = contactData.email; // this.setStatesListEvent(this.contact.Business.Address.country, 0);
        this.formdata.contact.send_invoices_email = contactData.send_invoices_email; 
        this.setCountryCitiesListEvent(
          contactData.Business.Address.country,
          0
        );
        this.formdata.address.city = contactData.Business.Address.city;
        this.formdata.address.state = contactData.Business.Address.state;
        this.formdata.address.zip = contactData.Business.Address.zip;

        this.formdata.contact.Phones = [this.phoneNumberParser(contactData.Phones[0].phone && contactData.Phones[0].phone.length === 10 ? `+1${contactData.Phones[0].phone}` : `+${contactData.Phones[0].phone}`, contactData.Phones[0])];


        this.formdata.departments = data.departments;
        this.formdata.next_review_date = data.next_review_date;


        this.originalFormdata = JSON.parse(JSON.stringify(this.formdata));
        this.disableSave = JSON.stringify(this.formdata) === JSON.stringify(this.originalFormdata);
      } catch (error) {
        console.log(error, "error");
      }
    },
    phoneMask(code) {
      switch (parseInt(code)) {
        case 1:
          return "(###) ###-####";
        case 61:
          return "#-#### ####";
        case 64:
          return "#-#### ####";
        case 91:
          return "####-######";
        default:
          return "### #### ####";
      }
    },
    validateEmailPhone() {
      if (!this.formdata.contact.email && !formdata.contact.phone) {
        return true;
      }
      return false;
    },
    checkValidPhone(code, number) {
      const emailPhoneError = "Either Email or Phone is required";
      const emailPhoneRequired = this.errors.items.indexOf(emailPhoneError);
      let parsedPhoneNumber

      if (!this.validateEmailPhone() && emailPhoneRequired >= 0) {
        this.errors.items.splice(emailPhoneRequired);
      }
      if (code && number) {
        try {
          parsedPhoneNumber = parsePhoneNumber("+" + code + number);

        } catch (error) {
          console.log("handling parse error: " + error);
          // return false
        }

        var r = null;
        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
          this.invalidPhone = false;
          r = true;
        } else if (
          (!parsedPhoneNumber ||
            (parsedPhoneNumber && !parsedPhoneNumber.isValid())) &&
          number?.length > 0
        ) {
          this.invalidPhone = true;
          r = false;
        } else {
          this.invalidPhone = false;
          r = true;
        }
        return r;
      }
    },
    setCountry() {
      this.formdata.address.city = "";
      this.formdata.address.state = "";
      this.setStatesListEvent(this.contact.Business.Address.country, 0);
      this.setCountryCitiesListEvent(this.contact.Business.Address.country, 0);
    },
    async addDepartment() {
      let department_status = await this.$validator.validate('department.department-name');
      if (!department_status) {
        this.showMessageNotification({
          type: 'error',
          description: 'There are errors in your form, correct them before continuing.',
          list: this.errors.items
        });
        return;
      } else {
        // Check if the maximum number of departments (20) has been reached
        if (this.formdata.departments?.length >= 20) {
          this.showMessageNotification({
            type: 'error',
            description: 'You can only create a maximum of 20 departments.'
          });
          return;
        } else {
          // Trim and check if departmentName already exists in departments array (case insensitive)
          let trimmedDepartmentName = this.departmentName.trim();
          let lowerCaseTrimmedDepartmentName = trimmedDepartmentName.toLowerCase();
          if (!this.formdata.departments.some(department => department.name.trim().toLowerCase() === lowerCaseTrimmedDepartmentName)) {
            this.formdata.departments.push({ "name": trimmedDepartmentName });
            this.addedDepartments.push({ "name": trimmedDepartmentName });
            this.departmentName = '';
          } else {
            this.showMessageNotification({
              type: 'error',
              description: 'Department already exists.'
            });
          }
        }
      }
    },
    handleChipCloseClick(departmentKeyToRemove) {
      // Find the index of the department to remove
      const departmentIndex = this.formdata.departments.findIndex(dept => dept.name === departmentKeyToRemove);
      // Remove the department from formdata.departments
      const [removedDepartment] = this.formdata.departments.splice(departmentIndex, 1);
      if (removedDepartment.id) {
        this.deletedDepartments.push(removedDepartment);
      }
      else {
        this.addedDepartments = this.addedDepartments.filter((e) => {
          return e.name != departmentKeyToRemove
        })
      }
    },
    handleEmittedSave(label) {
      if (!(this.hasPermission("manage_national_accounts"))) {
        this.showNoPermissionDialog = true
        return
      }

      let param = "";
      switch (label) {
        case "Company Name":
          param = "default.company-name";
          break;
        case "Trading Name":
          param = "default.trading_name";
          break;
        case "Country":
          param = "default.country";
          break;
        case "Business Number":
          param = "default.bNumber";
          break;
        case "Business Address":
          param = "address.*";
          break;
        case "Sqm Discount":
          param = "discount.*";
          break;
        case "Primary Contact":
          param = "contact.*";
          break;
        case "Next Review Date":
          param = "default.next_review_date";
          break;
        case "Departments":
          param = "discount.*";
          break;
        case "PO Number":
          param = "default.po_number";
          break;
        case "Billing Cycle":
          param = "default.billingCycle";
          break;
      }
      this.$validator.validate(param).then(async (valid) => {
        if (!valid) {
          this.showMessageNotification({
            type: "error",
            list: this.errors.items,
            description:
              "There are errors in your form, correct them before continuing.",
          });
          return;
        }
        else {
          if (!this.checkValidPhone(this.formdata.contact.Phones[0].code, (this.formdata.contact.Phones[0].number.toString().replace(/\D/g, '')))) {
            console.log("this.formdata.contact.Phones[0].phone",this.formdata.contact.Phones[0].phone,"this.formdata.contact.Phones[0].number",this.formdata.contact.Phones[0].number);
            this.showMessageNotification({
              type: "error",
              list: ['Invalid Phone Number'],
              description: "There are errors in your form, correct them before continuing."
            });
          }
          else {
            try {
              this.formdata['addedDepartments'] = this.addedDepartments;
              this.formdata['deletedDepartments'] = this.deletedDepartments;
              // Remove the departments array
              let editedData = JSON.parse(JSON.stringify(this.formdata));
              delete editedData.departments
              if (!editedData.promotion_id) {
                editedData.promotion_id = '';
                editedData.old_promotion_id = this.temp_promotion_id
              }
              editedData.contact.Phones[0] = {};
              // Add new data from buildSavePhoneObject
              editedData.contact.Phones[0] = this.buildSavePhoneObject(this.formdata.contact.Phones[0]);
              await api.put(this, api.NATIONAL_ACCOUNT + `${this.$route.query.na_id}`, editedData).then(async (res) => {
                this.active = "";
                this.showMessageNotification({
                  type: "success",
                  description: `${label} Updated Successfully`
                });
               
                if (label === 'Company Name' || label === 'Business Address') {
                  EventBus.$emit('contact_updated_for_mini_profile');
                }
               this.$emit('fetchContact')
                
              })
            } catch (error) {
              console.log(error, "error")
              this.active = "";
              this.showMessageNotification({
                type: "error",
                list: [error],
                description: "Something Went Wrong."
              });
            }
          }

        }
      });
    },
    async handleEmittedCancel(label) {
      let param = "";
      switch (label) {
        case "Company Name":
          if (!this.disableSave) await this.setAccountInfo()
          break;
        case "Trading Name":
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "Country":
          break;
        case "Business Number":
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "Business Address":
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "Sqm Discount":
          if (!this.disableSave) this.setAccountInfo()
          param = "discount.*";
          break;
        case "Primary Contact":
          if (!this.disableSave) this.setAccountInfo();
          break;
        case "Next Review Date":
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "Departments":
          this.departmentName = ''
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "PO Number":
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "Billing Cycle":
          if (!this.disableSave) this.setAccountInfo()
          break;
        case "Name":
        case "Phone":
        case "Email":
        case "Title":
          if (!this.disableSave) this.updateComponent()
          break;

      }

    },
    async saveContact() {
      this.loading = true
      // this.showEmailPhoneValidation(this.form.Relationships[this.form.Relationships.length-1].Contact.email && this.form.Relationships[this.form.Relationships.length-1].Contact.Phones.number ? false : true,this.errors)
      let validationFlag = await this.$validator.validateAll('contacts')
      // validate if phone and email both are empty
      if (!validationFlag) {
        this.loading = false
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return
      }
      let data = this.buildSaveRelationShipObject()


      // after validation task
      // to save additional contact
      let r = await api.put(this, api.CONTACTS + this.contact.id + "?scope=contacts", data);
      this.$emit('fetchContact')
    },
    buildSaveRelationShipObject() {
      let data = JSON.parse(JSON.stringify(this.contact));
      delete data.Access
      delete data.access
      delete data.active_military
      delete data.Addresses[0].lat
      delete data.Addresses[0].lng
      delete data.Addresses[0].neighborhood
      delete data.Business
      delete data.company_id
      delete data.email_verified
      delete data.have_secondary_contact
      delete data.id
      delete data.Lead
      delete data.Leads
      delete data.Leases
      delete data.is_returning_contact
      // delete data.Military
      delete data.national_account_id
      delete data.NationalAccount
      delete data.Pending
      delete data.Representative
      delete data.Reservations
      delete data.source
      delete data.status
      delete data.user_id
      delete data.verification_token
      data.Relationships = JSON.parse(JSON.stringify(this.form.Relationships))
      data.Relationships == data.Relationships.map(item => {
        // delete item.id
        delete item.lease_data
        delete item.selected_designations_list
        delete item.selected_leases_list
        // delete item.type
        item.related_contact_id = ''
        item.is_alternate = 0
        item.is_authorized = 0
        item.is_business_representative = 0
        item.is_cosigner = 0
        item.is_emergency = 0
        item.is_lien_holder = 0
        item.is_military = 0
        item.is_national_account_alt = 1
        item.Contact.Phones[0] = this.buildSavePhoneObject(item.Contact.Phones[0])
      })
      return data
    },
    buildSavePhoneObject(phoneObj) {
      let phone = { phone: '+' + phoneObj.code + '' + phoneObj.number.toString().replace(/\D/g, "") }
      phone.type = phoneObj.type
      phone.id = phoneObj.id
      phone.sms = phoneObj.sms
      return phone;

    },
    buildFormRelationShip() {
      let relationships = this.form.Relationships.map(additionalContacts => {
        additionalContacts.Contact.Phones = additionalContacts.Contact.Phones.map(p => {
          let ph = p.phone && p.phone.length === 10 ? `+1${p.phone}` : `+${p.phone}`;
          return this.phoneNumberParser(ph, p);
        })
      })
      this.originalRelationshipData = JSON.stringify(this.form.Relationships)
    },
    phoneNumberParser(phoneNo, phoneObj) {
      const { phone, id, sms, type } = phoneObj || {};
      try {
        var parseEditedPhone = parsePhoneNumber(phoneNo);
      } catch (err) {
        console.log(err, "ERRORRRRRR");
      }
      let parsedObj = {
        id,
        sms,
        phone,
        type
      }
      if (!parseEditedPhone) {
        parsedObj = {
          ...parsedObj,
          code: '1',
          number: '',
        }
      } else {
        parsedObj = {
          ...parsedObj,
          code: parseEditedPhone.countryCallingCode,
          number: parseEditedPhone.nationalNumber,
        }
      }
      return parsedObj;
    },
    updateComponent() {
      this.form.Relationships = JSON.parse(JSON.stringify(this.contact.Relationships))
      this.buildFormRelationShip()
      this.newContact = false
      this.disableSave = true
    },
    removeContact(i) {
      this.selectedContact = i;
      this.confirmRemoveContact = true
    },
    deleteRelationship(i) {
      this.form.Relationships.splice(i, 1);
      this.saveContact()
      this.confirmRemoveContact = false;
    },
    async handleAdditionalContactSave(label, index) {
      let validateFlag = await this.$validator.validateAll('contacts')      
      // validate phone separately 
      let PhoneFLag = this.checkValidPhone(this.form.Relationships[index].Contact.Phones[0].code,this.form.Relationships[index].Contact.Phones[0].number.toString().replace(/\D/g, ""))
      if (!validateFlag || !PhoneFLag) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        return
      }
      await this.saveContact()
      this.active='';

    }

  }
};
</script>

<style lang="css" scoped>
.hb-aviary-form-label-150 {
  min-width: 15% !important;
  max-width: 15% !important;
}
.text-field-wrapper-parent >>>.hb-text-field-wrapper .v-input__prepend-inner .custom-chip-block .v-icon{
    margin-bottom: 0px !important
  }

.account-info-wrapper {
  max-width: 100%; 
  padding-right: 15px; 
  padding-top: 10px;
}

@media (max-width: 600px) {
  .account-info-wrapper {
  padding-right: 0px; 
  }
}

</style>

